import React from 'react';
import cx from 'classnames';

const Paragraph = ({ attributes: { content, align, fontSize, className } }) => {
	const rootClass = cx(className, {
		[`p-${fontSize}`]: fontSize,
	});

	return (
		<p
			className={rootClass}
			style={{ textAlign: align }}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	);
};

export default Paragraph;
