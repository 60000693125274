/**
 * Convert number range
 * @param {Number} number to convert
 * @param {Number} old range min
 * @param {Number} old range max
 * @param {Number} new range min
 * @param {Number} new range max
 */

import languages from '@data/languages';

export const formatDate = (langSlug, date) => {
	let months;
	languages.forEach((language) => {
		if (language.slug === langSlug) {
			months = language.assets.months;
		}
	});

	let current_datetime = new Date(date);
	let formatted_date =
		current_datetime.getDate() +
		' ' +
		months[current_datetime.getMonth()] +
		' ' +
		current_datetime.getFullYear();

	return formatted_date;
};
