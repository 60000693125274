import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useHover from '@assets/scripts/hooks/useHover';
// assets
import Link from '@components/atoms/LinkPage';
import { graphql } from 'gatsby';
import Image from '@components/atoms/Image';

const Author = ({
	modifiers,
	className,
	image,
	name,
	profileLink,
	date,
	category,
	categoryLink,
	...otherProps
}) => {
	const baseClass = 'author';

	const rootClass = cx(baseClass, className, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	// interaction
	const [hoverOn, hoverOff] = useHover();

	return (
		<div className={rootClass} data-author {...otherProps}>
			{profileLink ? (
				<Link
					to={profileLink}
					data-target
					onMouseEnter={() => hoverOn()}
					onMouseLeave={() => hoverOff()}
					className="author__img-link"
				>
					<Image {...image} className="author__img" alt="" />
				</Link>
			) : (
				<span className="author__img-link">
					<Image {...image} className="author__img" alt="" />
				</span>
			)}
			<div className="author__details">
				{profileLink ? (
					<Link
						to={profileLink}
						className="author__name"
						data-target
						onMouseEnter={() => hoverOn()}
						onMouseLeave={() => hoverOff()}
					>
						{name}
					</Link>
				) : (
					<p className="author__name">{name}</p>
				)}
				<p className="author__date">{date}</p>
				{categoryLink && (
					<div className="author__category">
						<Link
							to={categoryLink}
							data-target
							onMouseEnter={() => hoverOn()}
							onMouseLeave={() => hoverOff()}
						>
							{category}
						</Link>
					</div>
				)}
				{category && !categoryLink && (
					<div className="author__category">
						<p>{category}</p>
					</div>
				)}
			</div>
		</div>
	);
};

Author.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	name: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	category: PropTypes.string,
	profileLink: PropTypes.string,
	categoryLink: PropTypes.string,
};

export default Author;

export const query = graphql`
	fragment AuthorFragment on WPGraphQL_Post {
		author {
			slug
			name
			firstName
			lastName
			description
			customAvatar {
				image {
					sourceUrl # necessary for imageFile resolver to work
					imageFile {
						childImageSharp {
							fixed(
								width: 80
								height: 80
								cropFocus: CENTER
								quality: 90
							) {
								...GatsbyImageSharpFixed_withWebp_noBase64
							}
						}
					}
				}
			}
		}
	}
`;
