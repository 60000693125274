import React from 'react';
import cx from 'classnames';

const Heading = ({
	attributes: { align, anchor, className, content, level },
}) => {
	const rootClass = cx(className, {
		// [`h-${align}`]: align,
	});

	const HTag = `h${level}`;

	return (
		<HTag
			id={anchor} // will have no id if anchor is null
			className={rootClass}
			style={{ textAlign: align }}
			dangerouslySetInnerHTML={{ __html: content }} // we want to parse special characters maybe (&nbsp; etc)
		/>
	);
};

export default Heading;
