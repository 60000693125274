import React from 'react';
import cx from 'classnames';
import Img from 'gatsby-image';

const Image = ({
	attributes: {
		align,
		alt,
		caption,
		className,
		width,
		sizeSlug,
		title,
		imageFile,
	},
}) => {
	const rootClass = cx(className, {
		[`figure-${sizeSlug}`]: sizeSlug,
		[`figure-${align}`]: align,
	});

	// i.e. gifs cannot be used with gatsby-image
	const isSharp = imageFile.ext !== '.gif';

	return (
		<figure>
			{isSharp ? (
				<Img
					className={rootClass}
					fluid={imageFile.childImageSharp.fluid}
					alt={alt}
					title={title}
					style={{ maxWidth: width }}
				/>
			) : (
				<img
					className={rootClass}
					src={imageFile.publicURL}
					alt={alt}
					title={title}
					loading="lazy"
				/>
			)}
			{caption ? (
				<figcaption
					dangerouslySetInnerHTML={{ __html: caption }}
				></figcaption>
			) : null}
		</figure>
	);
};

export default Image;
