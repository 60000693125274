/**
 * Get video id from a youtube video link
 * @param {String} url
 */

export const getYoutubeVideoId = (url) => {
	const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
	const match = url.match(regExp);

	return match && match[2].length === 11 ? match[2] : null;
};
