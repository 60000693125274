import '@assets/scss/pages/article.scss';

import React, { useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { formatDate } from '@assets/scripts/helpers/formatDate';
import useHover from '@assets/scripts/hooks/useHover';
import { getYoutubeVideoId } from '@assets/scripts/helpers/getYoutubeVideoId';

// data
import i18n from '@data/i18n';

// components
import Layout from '@src/layouts';
import HeroArticle from '@components/molecules/heros/HeroArticle';
import Author from '@components/molecules/Author';

// gutenberg blocks
import Paragraph from '@components/blocks/core/paragraph';
import Heading from '@components/blocks/core/heading';
import Image from '@components/blocks/core/image';
// vars
const pageClass = 'page-article';
const DATA_POST_LINKS = 'article a';

const PostPage = ({
	pageContext: { blogPath, translations },
	data,
	location,
}) => {
	// vars
	const post = data.wpgraphql.post;
	const lang = post.language.slug;
	const categoryName = post?.categories?.nodes?.[0]?.name ?? null;
	const blocks = data.wpgraphql.post.blocks;
	let relatedArticles = [];
	//refs
	const postLinks = useRef([]);

	// interaction
	const [hoverOn, hoverOff] = useHover();

	// grow circle on links
	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		postLinks.current = document.querySelectorAll(DATA_POST_LINKS);

		postLinks.current.forEach((link) => {
			link.addEventListener('mouseenter', hoverOn);
			link.addEventListener('mouseleave', hoverOff);
			link.setAttribute('data-target', true);
		});

		return () => {
			postLinks.current.forEach((link) => {
				link.removeEventListener('mouseenter', hoverOn);
				link.removeEventListener('mouseleave', hoverOff);
			});
		};
	}, []);
	/* eslint-enable react-hooks/exhaustive-deps */

	// related articles
	if (
		post.relatedArticles &&
		post.relatedArticles.relatedArticles &&
		Array.isArray(post.relatedArticles.relatedArticles)
	) {
		relatedArticles = post.relatedArticles.relatedArticles.reduce(
			(acc, curr) => {
				const relatedArticleFormatted = {
					title: curr.titleOverride || curr.relatedArticle.title,
					uptitle:
						curr.uptitleOverride ||
						(Array.isArray(curr.relatedArticle.categories.nodes)
							? curr.relatedArticle.categories.nodes[0].name
							: null),
					// curr.relatedArticle.primary_category.name,
					url: curr.relatedArticle.uri,
				};

				return [...acc, relatedArticleFormatted];
			},
			[]
		);
	}

	const isGifImage = post.featuredImage.mimeType === 'image/gif';

	return (
		<Layout
			language={lang}
			footerRelatedLinks={relatedArticles}
			location={location}
			translations={translations}
			seo={{
				title: post.title,
				image: post.featuredImage?.imageFile?.publicUrl,
				description: post.excerpt.replace(/(<([^>]+)>)/gi, ''),
				isBlogPost: true,
			}}
		>
			<Helmet
				bodyAttributes={{
					class: `page ${pageClass}`,
				}}
			/>
			<HeroArticle
				breadcrumb={{
					title: i18n[lang].posts,
					link: blogPath,
				}}
				category={categoryName}
				title={post.title}
				image={{
					...(!isGifImage && {
						...post.featuredImage?.imageFile?.childImageSharp,
					}),
					...(isGifImage && {
						src: post.featuredImage.sourceUrl,
						type: 'gif',
					}),
				}}
			/>
			<section className="section-author grid" data-animation-page>
				<Author
					image={
						post.author.customAvatar?.image?.imageFile
							?.childImageSharp
					}
					name={post.author.name}
					profileLink="#"
					date={formatDate(lang, post.date)}
					category={categoryName}
					categoryLink="#"
				/>
			</section>
			<article
				className="grid-content grid text-content"
				data-animation-page
				// dangerouslySetInnerHTML={{ __html: post.content }}
			>
				{blocks.map(({ name, ...props }, i) => {
					if (name === 'core/paragraph') {
						return <Paragraph key={i} {...props} />;
					}
					if (name === 'core/heading') {
						return <Heading key={i} {...props} />;
					}
					if (name === 'core/image') {
						return <Image key={i} {...props} />;
					}
					if (
						['core-embed/vimeo', 'core-embed/youtube'].includes(
							name
						)
					) {
						const {
							providerNameSlug,
							className,
							url,
							caption,
						} = props.attributes;

						const videoUrl =
							providerNameSlug === 'youtube'
								? `//www.youtube.com/embed/${getYoutubeVideoId(
										url
								  )}`
								: url;

						return (
							<figure
								key={i}
								className={`wp-block-embed-${providerNameSlug} wp-block-embed is-type-video is-provider-${providerNameSlug} ${
									className || ''
								}`}
							>
								<div className="wp-block-embed__wrapper">
									{/* eslint-disable-next-line */}
									<iframe
										loading="lazy"
										src={videoUrl}
										frameBorder="0"
									></iframe>
								</div>
								{caption ? (
									<figcaption>{caption}</figcaption>
								) : null}
							</figure>
						);
					}
					// return <></>;
					return (
						<div
							key={i}
							dangerouslySetInnerHTML={{
								__html: props.originalContent,
							}}
						/>
					);
				})}
			</article>
			{/* {post.author.descriptions[lang] && ( */}
			{post.author.description && (
				<section
					className="grid-content grid text-content"
					data-animation-page
				>
					<h2>{`${i18n[lang].aboutAuthor} ${post.author.firstName}`}</h2>
					{/* <p>{post.author.descriptions[lang]}</p> */}
					<p>{post.author.description}</p>
				</section>
			)}
			<div className="page__background" data-animation-page></div>
		</Layout>
	);
};

export default PostPage;

export const query = graphql`
	query($databaseId: ID!) {
		wpgraphql {
			post(id: $databaseId, idType: DATABASE_ID) {
				#-------------------
				# COMPONENT data
				#-------------------

				id
				databaseId
				uri
				title
				excerpt(format: RENDERED)
				date
				categories {
					nodes {
						databaseId
						uri
						name
					}
				}
				language {
					code
					slug
				}
				translations {
					id
					databaseId
					uri
				}
				relatedArticles {
					relatedArticles {
						relatedArticle {
							... on WPGraphQL_Post {
								databaseId
								uri
								title
								excerpt
								categories {
									nodes {
										id
										uri
										name
									}
								}
							}
						}
						titleOverride
						uptitleOverride
					}
				}

				#-------------------
				# SUBCOMPONENTS data
				#-------------------

				...HeroArticleImageFragment
				...AuthorFragment

				#-------------------
				# BLOCKS
				#-------------------

				blocks {
					## any block
					name
					# dynamicContent
					# originalContent
					# saveContent

					## core/buttons
					#... on WPGraphQL_CoreButtonsBlock {
					#	attributes {
					#		... on WPGraphQL_CoreButtonsBlockAttributes {
					#			align
					#			className
					#		}
					#	}
					#	innerBlocks {
					#		... on WPGraphQL_CoreButtonBlock {
					#			name
					#			# dynamicContent
					#			# originalContent
					#			# saveContent
					#			attributes {
					#				... on WPGraphQL_CoreButtonBlockAttributes {
					#					align
					#					className
					#					text
					#					title
					#					url
					#					linkTarget
					#				}
					#			}
					#		}
					#	}
					#}

					## core/paragraph
					... on WPGraphQL_CoreParagraphBlock {
						attributes {
							... on WPGraphQL_CoreParagraphBlockAttributes {
								align
								className
								fontSize
								content
							}
						}
					}

					## core/heading
					... on WPGraphQL_CoreHeadingBlock {
						attributes {
							... on WPGraphQL_CoreHeadingBlockAttributes {
								align
								anchor
								className
								content
								level
							}
						}
					}

					## core/image
					... on WPGraphQL_CoreImageBlock {
						attributes {
							... on WPGraphQL_CoreImageBlockAttributes {
								align
								alt
								caption
								className
								width
								sizeSlug
								title
								url # necessary for imageFile resolver to work
								imageFile {
									ext
									publicURL
									childImageSharp {
										# TODO: define sizes?
										# @see https://www.gatsbyjs.org/docs/gatsby-image/#fluid-image-query-parameters
										fluid(maxWidth: 1600, quality: 90) {
											...GatsbyImageSharpFluid_withWebp_noBase64
										}
									}
								}
							}
						}
					}

					## core-embed/vimeo
					... on WPGraphQL_CoreEmbedVimeoBlock {
						attributes {
							... on WPGraphQL_CoreEmbedVimeoBlockAttributes {
								align
								caption
								allowResponsive
								className
								url
								type
								providerNameSlug
							}
						}
					}

					## core-embed/youtube
					... on WPGraphQL_CoreEmbedYoutubeBlock {
						attributes {
							... on WPGraphQL_CoreEmbedYoutubeBlockAttributes {
								align
								caption
								allowResponsive
								className
								url
								type
								providerNameSlug
							}
						}
					}

					## core/list
					... on WPGraphQL_CoreListBlock {
						originalContent
					}

					## core/quote
					... on WPGraphQL_CoreQuoteBlock {
						originalContent
					}

					## core/html
					... on WPGraphQL_CoreHtmlBlock {
						originalContent
					}
				}
			}
		}
	}
`;

// export const query = graphql`
// 	query($id: String!) {
// 		allWordpressPost(filter: { id: { eq: $id } }) {
// 			edges {
// 				node {
// 					id
// 					title
// 					excerpt
// 					content
// 					slug
// 					primary_category {
// 						name
// 						slug
// 					}
// 					date(formatString: "DD MMMM YYYY")
// 					featured_media {
// 						id
// 						source_url
// 					}
// 					author {
// 						id
// 						name
// 						first_name
// 						last_name
// 						descriptions {
// 							en
// 							fr
// 						}
// 						avatar_custom
// 						avatar_urls {
// 							wordpress_96
// 						}
// 					}
// 					polylang_translations_custom {
// 						wordpress_id
// 						locale
// 						path
// 					}
// 					lang
// 					acf {
// 						related_articles {
// 							title_override
// 							uptitle_override
// 							related_article {
// 								post_title
// 								path
// 								primary_category {
// 									name
// 									slug
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// `;
